import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import Meta from '../components/meta';

const sizes = {
  desktop: 1920,
  laptopL: 1440,
  laptop: 1024,
  tablet: 768,
  mobile: 425,
};

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const Work = ({ data }) => (
  <>
    <Meta title="Work" keywords={['creative tech', 'developer', 'portfolio', 'Bryan Ma']} />
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      { [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1].map(({ el, i }) => (
        <div
          key={i}
          style={{
            backgroundColor: 'rgba(200, 80, 120, 1)',
            width: 100 + Math.random() * 250,
            height: '150px',
            margin: '1em 0',
          }}
        >
          <img style={{width: '100%', height: '100%'}} src="https://source.unsplash.com/random/400x200" alt="" />
        </div>
      )) }

      { /* data.allMarkdownRemark.edges.map(({ node, i }) => (
        <div
          key={node.frontmatter.title}
          style={{
            backgroundColor: 'red', width: '200px', height: '100px'
          }}
        >
          {node.excerpt}
        </div>
      )) */ }
    </div>
  </>
);

export default Work;

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            date
          }
          excerpt
        }
      }
    }
  }
`;
